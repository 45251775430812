<template>
    <div>
        <v-container class="my-2">
            <v-layout row wrap px-0 mb-4>
                <v-flex xs9>
                    <v-breadcrumbs class="px-0" :items="$store.getters.language.data.sections.list_path">
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item :to="item.path" :disabled="item.disabled">
                                {{ item.title.toUpperCase() }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                </v-flex>
                <v-flex xs3>
                    <v-btn color="primary" outlined class="float-end" to="/add-section">
                        <v-icon>
                            mdi-plus
                        </v-icon>
                        Add New Section
                    </v-btn>
                </v-flex>
            </v-layout>

            <v-layout row wrap mt-5>
                <v-flex xs12>
                    <v-card flat>
                        <v-card-text>
                            <v-data-table :loading="loading" :headers="headers" :search="search" :items="rows"
                                class="elevation-0" item-key="section_id">
                                <template v-slot:[`item.section_id`]="{ item }">
                                    <div>
                                        <v-btn icon :to="'/sections-list/'+item.section_id" color="teal" class="mx-1">
                                            <v-icon> mdi-pencil-outline </v-icon>
                                        </v-btn>
                                        <v-menu offset-y>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn color="error" icon class="mx-1" v-bind="attrs" v-on="on">
                                                    <v-icon> mdi-delete-outline </v-icon>
                                                </v-btn>
                                            </template>
                                            <v-card>
                                                <v-card-text>
                                                    {{$store.getters.language.data.sections.delete_question}}
                                                </v-card-text>
                                                <v-card-actions>
                                                    <v-spacer></v-spacer>
                                                    <v-btn text class="elevation-0">
                                                        {{$store.getters.language.data.sections.cancel_btn}}
                                                    </v-btn>
                                                    <v-btn color="error" class="elevation-0"
                                                        @click="deleteSections(item.section_id)">
                                                        {{$store.getters.language.data.sections.yes_btn}}
                                                    </v-btn>
                                                </v-card-actions>
                                            </v-card>
                                        </v-menu>
                                    </div>
                                </template>
                            </v-data-table>
                        </v-card-text>

                    </v-card>
                </v-flex>
            </v-layout>
        </v-container>

        <v-snackbar v-model="snackbar.value" :color="snackbar.color">
            {{snackbar.text}}
        </v-snackbar>
    </div>
</template>
<script>
    import requests from './../../requests/sections.request.js'
    import * as xlsx from 'xlsx/xlsx.js';
    export default {
        data() {
            return {
                sections: {},
                search: '',
                loading: true,
                loading_btn: false,

                snackbar: {
                    value: false,
                    text: '',
                    color: ''
                },
                rows: [],

                selected_sections: {},
                headers: [



                    {
                        text: this.$store.getters.language.data.sections.section_name_en,
                        align: 'start',
                        sortable: true,
                        value: 'section_name_en',
                    },



                    {
                        text: this.$store.getters.language.data.sections.section_status,
                        align: 'start',
                        sortable: true,
                        value: 'section_status',
                    },
                    {
                        text: this.$store.getters.language.data.sections.section_index,
                        align: 'start',
                        sortable: true,
                        value: 'section_index',
                    }, {
                        text: this.$store.getters.language.data.actions,
                        align: 'start',
                        sortable: true,
                        value: 'section_id',
                    }
                ],
            }
        },
        computed: {
            page() {
                const pageKey = 'SECTIONS'
                return {}
            }
        },
        mounted() {
            this.readSections();
        },
        methods: {


            deleteSections(section_id) {
                requests.deleteSections(section_id).then(r => {
                    if (r.status == 200) {
                        this.rows = this.rows.filter(f => {
                            return f.section_id != section_id
                        })
                        this.snackbar = {
                            value: true,
                            text: 'Sections Deleted',
                            color: 'success'
                        }
                    } else {
                        this.snackbar = {
                            value: true,
                            text: 'Delete Faild',
                            color: 'error'
                        }
                    }
                })
            },

            readSections() {
                this.loading = true
                requests.getAllSections().then(r => {
                        if (r.status == 200) {
                            this.rows = r.data.sections

                            this.loading = false
                        } else {
                            this.snackbar = {
                                value: true,
                                text: 'Fail to read Sections',
                                color: 'error'
                            }
                        }
                    })
                    .catch(e => {
                        this.snackbar = {
                            value: true,
                            text: 'Fail to read Sections',
                            color: 'error'
                        }
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },
            selectSections(i) {
                this.selected_sections = i
                this.delete_dialog = true
            },
        },
    }
</script>